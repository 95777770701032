import styled from "styled-components";
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  scroll-behavior: normal scroll;
  overflow: auto;
  scrollbar-width: none;
  background-color: black;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: black;
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
export const Form = styled.form`
  min-height: 800px;
  /* width: 375px;    */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: white;
  margin: 10px;
`;

export const Profile = styled.img`
  width: calc(100vw / 15);
  height: calc(100vw / 15);
  object-fit: contain;
  margin-right: calc(100vw / 35);
`;

export const Logo = styled.img`
  width: calc(100vw / 3.5);
  height: calc(100vw / 3.5);
  margin-top: 15%;
  object-fit: contain;
  border-radius: 100px;
`;
export const Button = styled.button`
  border-radius: calc(100vw / 16);
  height: calc(100vw / 7.5);
  width: calc(100vw / 2.3);
  padding: calc(100vw / 26);
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
`;
export const TitleBtn = styled.p`
  font-family: "Lato", sans-serif;
  font-size: calc(100vw / 20);
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #0d0d0d;
  margin: 0px;
`;

export const Title = styled.div`
  width: 100%;
  overflow: hidden;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: calc(100vh / 30);
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
  color: #ffffff;
`;

export const TitleSmall = styled.p`
  /* margin-left: calc(100vw / 36); */
  /* max-width: 100%; */
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  /* color: #ffffff; */
  /* opacity: 0.5; */
  font-size: calc(100vw / 27);
  cursor: pointer;
`;

export const PlaceholderTitle = styled.div`
  width: 100%;
  margin-top: calc(100vw / 65);
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(100vw / 25);
  color: #fefeff;
  @media only screen and (max-width: 768px) {
    font-style: normal;
    font-weight: 400;
    font-size: calc(100vw / 24);
  }

  color: #fefeff;
`;

export const TickIcon = styled.div`
  height: 22px;
  margin-top: 20px;
  background: linear-gradient(98.81deg, #ffad1d -0.82%, #fead1d 101.53%);
  box-shadow: 12px 26px 50px rgba(90, 108, 234, 0.07);
  border-radius: 15px;
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 0;
  /* border: red 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  flex-direction: row;
  bottom: 0;
  position: fixed;
`;
export const MainPagesFooter = styled.footer`
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  height: calc(100vw / 8);
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1d1d1d;
  border-radius: calc(100vw / 18);
  margin-top: 1%;
  margin-bottom: calc(100vw / 45);
`;

export const SubContainer = styled.div`
  flex-direction: column;
  max-width: 84%;
  width: 100%;
  display: flex;
  padding: calc(100vw / 20) calc(100vw / 22);
  margin: calc(100vw / 25);
  background: #1e1e20;
  border-radius: calc(100vw / 15);

  @media only screen and (max-width: 768px) {
    min-width: 80%;
    margin: calc(100vw / 25);
  }
`;

export const Input = styled.input`
  width: 100%;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  padding: 0;
  font-size: calc(100vw / 20);
  border: none;
  outline: none;
  background-color: #343434;
  color: #f4f4f4;

  &::placeholder {
    color: #f4f4f4;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: calc(100vw / 20);
    opacity: 0.3;
  }
`;

// otp
export const HeaderContainer = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin-top: calc(100vh / 30);
  align-items: center;
`;

export const BackArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh / 18);
  width: calc(100vh / 15);
  border-radius: calc(100vh / 50);
  background-color: #2a2617;
  cursor: pointer;
`;

export const BackArrowImage = styled.img`
  width: calc(100vw / 20);
  height: calc(100vw / 20);
  object-fit: contain;
  /* position: absolute; */
  margin: 5%;
`;
export const LogoImg = styled.img`
  width: calc(100vw / 5);
  height: calc(100vw / 5);
  border-radius: 100px;
`;

// signup
export const InputDiv = styled.input`
  /* width: 90%; */
  margin-top: calc(100vh / 40);
  padding: calc(100vh / 38);
  font-size: calc(100vh / 40);
  border: none;
  outline: none;
  font-family: "Lato", sans-serif;
  background-color: rgb(52, 52, 52);
  color: #f4f4f4;
  outline: none;
  text-align: left;
  border-radius: calc(100vh / 70);
  height: calc(100vh / 40);

  &::placeholder {
    color: #f4f4f4;
    /* width: 90%; */
    font-size: calc(100vh / 40);
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    mix-blend-mode: normal;
    opacity: 0.3;
    text-align: left; /* Added */
  }
`;

// welcomeScreen

export const SubTitle = styled.div`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: calc(100vh / 38);
  text-align: center;
  text-align: center;
  color: #ffffff;
`;

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: calc(100vw / 6); */
  max-width: 100%;
`;

// Home

export const Div1 = styled.div`
  width: 100%;

  border-radius: 0px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

export const BannerImagesmall = styled.div`
  display: flex;
  cursor: pointer;
  background: rgb(29, 29, 29);
  border-radius: calc(100vh / 30);
  flex-direction: column;
  min-width: calc(100vh / 4);
  /* height: calc(100vh / 6); */
  align-items: flex-start;
`;

export const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: calc(3vh);
  object-fit: contain;
`;
export const BannerImagesmallimage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
`;

export const Span = styled.div`
  width: 100%;

  margin-left: calc(100vh / 45);
  margin-top: calc(100vh / 90);
  margin-bottom: calc(100vh / 90);
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: calc(100vh / 50);

  /* or 16px */

  color: rgba(255, 255, 255, 0.8);
`;
export const SpanDiv = styled.p`
  margin-left: calc(100vw / 36);
  margin-bottom: calc(100vw / 36);
  margin-top: calc(100vw / 17);
  max-width: 100%;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;

  color: rgba(255, 255, 255, 0.8);

  opacity: 1;
  font-size: calc(100vw / 24);
`;
